import {Component} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";
import {Regions} from "@core/services/flock-management.swagger";
import {TypedRegion} from "@core/models/typed-region";

@Component({
  selector: 'app-region-data',
  templateUrl: './region-data.component.html',
  styleUrls: ['./region-data.component.scss']
})
export class RegionDataComponent {
  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  get activeItem() {
    const activeValue = this._flockManagementService.activeItem.getValue();
    if (activeValue instanceof Regions) {
      return activeValue;
    }
    return undefined;
  }
}
