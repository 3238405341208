import {Component} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";
import {Grower} from "@core/services/flock-management.swagger";

@Component({
  selector: 'app-grower-data',
  templateUrl: './grower-data.component.html',
  styleUrls: ['./grower-data.component.scss']
})
export class GrowerDataComponent {
  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  get activeItem() {
    const activeValue =this._flockManagementService.activeItem.getValue()
    if (activeValue instanceof Grower) {
      return activeValue;
    }
    return undefined;
  }
}
