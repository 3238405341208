import {Component} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";
import {House} from "@core/services/flock-management.swagger";

@Component({
  selector: 'app-house-data',
  templateUrl: './house-data.component.html',
  styleUrls: ['./house-data.component.scss']
})
export class HouseDataComponent {
  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }


  get activeItem() {
    const activeValue = this._flockManagementService.activeItem.getValue()
    if (activeValue instanceof House) {
      return activeValue;
    }
    return undefined;
  }
}
